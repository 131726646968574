import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Login as LoginOrganism } from '@organisms/Login'

export const Login = () => (
	<Fragment>
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			style={{
				height: '100vh',
				backgroundImage: 'url(/login_background.jpg)',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<Grid
				container
				item
				xs={12}
				md={8}
				justifyContent='center'
				spacing={4}
			>
				<Grid item xs={12} sm={11} md={6}>
					<LoginOrganism />
				</Grid>
			</Grid>
		</Grid>
	</Fragment>
)
