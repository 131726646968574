import React from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'

import VpnKey from '@mui/icons-material/VpnKey'
import Email from '@mui/icons-material/Email'
import AccountCircle from '@mui/icons-material/AccountCircle'

import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import Router, { useRouter } from 'next/router'
import { useLogInMutation } from '@graphql'
import cookies from 'js-cookie'
import lowerFirst from 'lodash/lowerFirst'
import capitalize from 'lodash/capitalize'
import Divider from '@mui/material/Divider'
import lowerCase from 'lodash/lowerCase'
import { Roles } from '@utils/roles'

const useController = () => {
	const { formatMessage: f } = useIntl()
	const intl = useIntl()
	const [login, { loading, data }] = useLogInMutation()
	const { enqueueSnackbar } = useSnackbar()

	const router = useRouter()
	// const { locale } = router
	// const { i18n } = useTranslation();

	const currentLanguageFromCookies = cookies.get('current-language')

	const local = currentLanguageFromCookies
		? currentLanguageFromCookies
		: global.navigator?.language || 'fr-FR'


	const form = useFormik({
		initialValues: {
			email: '',
			password: '',
			role: 'vendor',
		},
		onSubmit: async ({ email, password, role }) => {
			// cookies.remove('token')
			try {
				console.log('before login >')
				const result = await login({ variables: { email, password } })
				console.log('result => ', result)
			} catch (e) {
				// console.log('error => ', e.response)
				enqueueSnackbar(f({ id: 'password.or.email.incorrect' }), {
					variant: 'error',
				})
			}
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(f({ id: 'email.error' }))
				.required(f({ id: 'field.required' })),
			password: Yup.string()
				.required(f({ id: 'field.required' }))
				.min(
					8,
					f({
						id: 'password.min.char',
					}),
				),
			// role: Yup.string()
			// 	.oneOf(
			// 		Roles(f).map((r) => r.value),
			// 		f({ id: 'role.notIncluded' }),
			// 	)
			// 	.required(f({ id: 'field.required' })),
		}),
	})
	console.log('before to set token etc .. ', data)
	if (data && data.customSignIn && data.customSignIn.success === true) {
		console.log('data.customSignIn', data.customSignIn)
		console.log(`Current locale: ${intl.locale}`)
		const currentRole = data?.customSignIn?.user?.lastAccountTypeUsed!
		cookies.set(
			`token-${lowerCase(currentRole)}`,
			data.customSignIn.sessionToken!,
			{
				expires: 10000,
				path: '/',
				sameSite: 'none',
				secure: true,
			},
		)
		cookies.set('current-language', local, {
			path: '/',
			sameSite: 'none',
			secure: true,
		})

		console.log('currentRole', currentRole)
		console.log('data.customSignIn.sessionToken', data.customSignIn.sessionToken)
		console.log('cookies token', cookies.get(`token-${lowerCase(currentRole)}`))

		if (data.customSignIn.user?.accounts.edges) {
			/* eslint no-underscore-dangle: 0 */
			// const currentRole =
			// 	data.customSignIn.user?.accounts.edges[0]?.node?.accountable
			// 		.__typename

			if (
				data.customSignIn.user?.accounts.edges.find(
					(accountEdge) =>
						accountEdge?.node?.accountable.__typename ===
						capitalize(
							data?.customSignIn?.user?.lastAccountTypeUsed!,
						),
				)
			) {
				Router.push(
					`/${lowerCase(
						data?.customSignIn?.user?.lastAccountTypeUsed!,
					)}/dashboard`,
				)
			} else {
				enqueueSnackbar(f({ id: 'login.wrong.account.type' }), {
					variant: 'error',
				})
			}

			// Router.push(`${lowerFirst(currentRole)}/dashboard`)
		}
	}

	if (data && data.customSignIn && data.customSignIn.success === false) {
		enqueueSnackbar(f({ id: 'password.or.email.incorrect' }), {
			variant: 'error',
		})
		// throw new Error(f({ id: 'password.or.email.incorrect' }))
	}
	const redirectToForgetPassword = () => {
		Router.push('/forgetPassword')
	}

	const redirectToChooseRole = () => {
		Router.push('/chooseYourRole')
	}
	return { form, f, loading, redirectToForgetPassword, redirectToChooseRole }
}

const view = ({
	form,
	f,
	loading,
	redirectToForgetPassword,
	redirectToChooseRole,
}: ReturnType<typeof useController>) => (
	<Card>
		<CardContent>
			<Grid container spacing={3} justifyContent='center'>
				<Grid item xs={12} />
				<Grid item xs={12}>
					<Typography variant='h3' align='center'>
						{f({ id: 'login' })}
					</Typography>
				</Grid>
				<Grid item xs={12} />
				<Grid item xs={10}>
					<TextField
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Email />
								</InputAdornment>
							),
						}}
						required
						type='email'
						name='email'
						helperText={form.errors.email}
						error={!!form.errors.email}
						label={f({ id: 'email' })}
						variant='filled'
						onChange={form.handleChange}
						value={form.values.email}
						fullWidth
					/>
				</Grid>
				<Grid item xs={10}>
					<TextField
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<VpnKey />
								</InputAdornment>
							),
						}}
						required
						type='password'
						name='password'
						helperText={form.errors.password}
						error={!!form.errors.password}
						label={f({ id: 'password' })}
						variant='filled'
						onChange={form.handleChange}
						onKeyPress={(ev) => {
							if (ev.key === 'Enter') {
								form.handleSubmit()
								ev.preventDefault()
							}
						}}
						value={form.values.password}
						fullWidth
					/>
				</Grid>
				{/* <Grid item xs={10}>
					<TextField
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<AccountCircle />
								</InputAdornment>
							),
						}}
						required
						select
						helperText={form.errors.role}
						error={!!form.errors.role}
						name='role'
						type='role'
						onChange={form.handleChange}
						value={form.values.role}
						label={f({ id: 'role' })}
						variant='filled'
						fullWidth
					>
						{Roles(f).map((role) => (
							<MenuItem key={role.value} value={role.value}>
								{role.label}
							</MenuItem>
						))}
					</TextField>
				</Grid> */}
				<Grid item xs={7}>
					{!loading && (
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={() => form.handleSubmit()}
						>
							{f({ id: 'enter' })}
						</Button>
					)}
					{loading && (
						<CircularProgress
							style={{
								display: 'block',
								margin: '0 auto',
							}}
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid
					item
					container
					xs={12}
					direction='row'
					alignItems='center'
				>
					<Grid item xs={6}>
						<Typography variant='body2' align='center'>
							{f({ id: 'password.forget' })}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Button
							onClick={() => redirectToForgetPassword()}
							size='small'
							variant='text'
						>
							{f({ id: 'password.ask.new' })}
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='body2' align='center'>
							{f({ id: 'signup' })} ?
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Button
							onClick={() => redirectToChooseRole()}
							size='small'
							variant='text'
						>
							{f({ id: 'createAccount' })}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
)

export const Login = () => view(useController())
